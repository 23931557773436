import React, { useState } from 'react'
import "./OurTeam.css"
import images from '../../constants/images'


const team_data = [
    {
        id: 1,
        name: "Ishita",
        img: require("../../asset/team_member_img2.jpeg"),
        desc: `
            <p>Meet Ishitta Ghelani – Founder of Ish Essence and Renitae</p>
            <p>Ishitta Ghelani, a licensed cosmetologist and founder of Ish Essence, is passionate about helping others feel their best, inside and out. With years of experience in the beauty and wellness industry, Ishitta blends science and nature to offer a range of treatments tailored to each client’s unique needs.</p>
            <p>At Ish Essence, we’re dedicated to offering holistic wellness. From Ayurvedic facials and rejuvenating scalp treatments to full-body waxing, every service is crafted with the same care and love. Whether you're here for relaxation, self-care, or personalized solutions, our goal is to nurture your skin, body, and mind.</p>
            <p>Renitae - a skincare brand dedicated to enhancing your natural beauty with nature-inspired, holistic skincare solutions.</p>
            <p>We priortise every client feeling heard, cared for, and guided on their journey to well-being, welcome aboard.</p>
        `
    },
    // {
    //     id: 2,
    //     name: "Selma",
    //     img: require("../../asset/team_member_img1.jpeg"),
    //     desc: "Hello! My Name is Ishita! I am an esthetician located in Bowling Green, KY. Since graduating from Aveda in 2022, I have strived to share my passion for beauty and skincare with others by honing my skills and building relationships with my clients. I cherish the time I get to spend in the treatment room, and hold myself to high ethical standards. I have spent the last year of my career becoming a Waxing Specialist, but I am excited to get back to my facial roots. Not only do I specialize in full body waxing, but in Ayurvedic facial methods. My goal is to make my clients forget their worries, leave relaxed, and feel like the best version of themselves. When I am not in the treatment room you can find me spending time with my family, watching movies, and spoiling my two cats Misha and Simba."
    // },
]

const OurTeam = () => {

    const [selectedMember, setSelectedMember] = useState(team_data[0]);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const handleClick = (member) => {
        setIsTransitioning(true); // Start transition
        setTimeout(() => {
            setSelectedMember(member);
            setIsTransitioning(false); // End transition after data update
        }, 200); // Adjust timing to match the transition duration
    };

    return (
        <div className='our_team_sec'>
            <div className='our_team_con'>
                <p className="our-service-menu-heading-main our_team_head">OUR TEAM</p>
                <div className='our_team_flex'>
                    <div className='our_team_flex_part1'>
                        {team_data.map((item) => (
                            <img
                                key={item.id}
                                src={item.img}
                                alt={item.name}
                                className={`team_member_img_small ${selectedMember.id === item.id ? 'team_member_img_small_active' : ''}`}
                                onClick={() => handleClick(item)}
                            />
                        ))}
                    </div>
                    <div className='our_team_flex_part2'></div>
                    <div className='our_team_flex_part3'>
                        <div className={`our_team_sub_part1 ${isTransitioning ? 'fade' : ''}`}>
                            <img src={selectedMember.img} alt={selectedMember.name} className='team_member_img_big' />
                        </div>
                        <div className={`our_team_sub_part2 ${isTransitioning ? 'fade' : ''}`}>
                            <p className='our_team_name'>{selectedMember.name}</p>
                            {/* <p className='our_team_desc'>{selectedMember.desc}</p> */}
                            <p className='our_team_desc'

                                dangerouslySetInnerHTML={{
                                    __html: selectedMember.desc,
                                }}></p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurTeam